/* HTML5 display-role reset for older browsers */
*{
  box-sizing:border-box;
  margin: 0;
	padding: 0;
  border: 0;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  background-color: #000;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 1em;
  line-height: 1;
}
ol, ul {
	list-style: none;
}
b {
	font-weight: bold;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 0.9em;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/*Refer to https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/*/
input {
  caret-color: white !important; /* caret is vertical bar that flashes on input */
  -webkit-text-fill-color: white !important; /* text color when hovered chromes autofill */
}

input::-webkit-contacts-auto-fill-button, input::-webkit-credentials-auto-fill-button {
  background-color: white !important;
}

/*
https://stackoverflow.com/questions/17480087/styling-scrollbar-for-google-chrome-browser
*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 30px rgba(0,0,0,0.4);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 30px rgba(255,255,255,0.8);
  border-radius: 4px;
}

/*input[type=number]::-webkit-inner-spin-button {*/
/*  -webkit-appearance: none;*/
/*}*/
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
