    /* ibm-plex-sans-200 - latin */
    @font-face {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 200;
        src: url('./fonts/ibm-plex-sans-v8-latin-200.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-200.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-200.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-200italic - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: italic;
        font-weight: 200;
        src: url('./fonts/ibm-plex-sans-v8-latin-200italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-200italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-200italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-300 - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 300;
        src: url('./fonts/ibm-plex-sans-v8-latin-300.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-300.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-300italic - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: italic;
        font-weight: 300;
        src: url('./fonts/ibm-plex-sans-v8-latin-300italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-300italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-300italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-regular - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 400;
        src: url('./fonts/ibm-plex-sans-v8-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-italic - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: italic;
        font-weight: 400;
        src: url('./fonts/ibm-plex-sans-v8-latin-italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-500 - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 500;
        src: url('./fonts/ibm-plex-sans-v8-latin-500.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-500.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-500italic - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: italic;
        font-weight: 500;
        src: url('./fonts/ibm-plex-sans-v8-latin-500italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-500italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-500italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-600 - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 600;
        src: url('./fonts/ibm-plex-sans-v8-latin-600.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-600.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
        /* ibm-plex-sans-600italic - latin */
        @font-face {
        font-family: 'IBM Plex Sans';
        font-style: italic;
        font-weight: 600;
        src: url('./fonts/ibm-plex-sans-v8-latin-600italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/ibm-plex-sans-v8-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/ibm-plex-sans-v8-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-600italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/ibm-plex-sans-v8-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/ibm-plex-sans-v8-latin-600italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
        }
    
        /* karla-200 - latin */
        @font-face {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 200;
        src: url('./fonts/karla-v15-latin-200.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-200.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-200.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-300 - latin */
        @font-face {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 300;
        src: url('./fonts/karla-v15-latin-300.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-300.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-300.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-regular - latin */
        @font-face {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 400;
        src: url('./fonts/karla-v15-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-regular.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-500 - latin */
        @font-face {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 500;
        src: url('./fonts/karla-v15-latin-500.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-500.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-500.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-600 - latin */
        @font-face {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 600;
        src: url('./fonts/karla-v15-latin-600.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-600.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-600.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-200italic - latin */
        @font-face {
        font-family: 'Karla';
        font-style: italic;
        font-weight: 200;
        src: url('./fonts/karla-v15-latin-200italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-200italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-200italic.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-300italic - latin */
        @font-face {
        font-family: 'Karla';
        font-style: italic;
        font-weight: 300;
        src: url('./fonts/karla-v15-latin-300italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-300italic.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-italic - latin */
        @font-face {
        font-family: 'Karla';
        font-style: italic;
        font-weight: 400;
        src: url('./fonts/karla-v15-latin-italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-italic.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-500italic - latin */
        @font-face {
        font-family: 'Karla';
        font-style: italic;
        font-weight: 500;
        src: url('./fonts/karla-v15-latin-500italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-500italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-500italic.svg#Karla') format('svg'); /* Legacy iOS */
        }
        /* karla-600italic - latin */
        @font-face {
        font-family: 'Karla';
        font-style: italic;
        font-weight: 600;
        src: url('./fonts/karla-v15-latin-600italic.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('./fonts/karla-v15-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/karla-v15-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/karla-v15-latin-600italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/karla-v15-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/karla-v15-latin-600italic.svg#Karla') format('svg'); /* Legacy iOS */
        }