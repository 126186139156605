[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
    border: 1px dashed transparent !important;
}

[dir="ltr"] .uppy-StatusBar-actions {
	right: 20px !important;
}

.uppy-size--md .uppy-Dashboard-files {
	padding-top: 0 !important;
}

.uppy-Dashboard-files {
	padding: 0 !important;
}

[dir="ltr"] .uppy-size--md .uppy-StatusBar-content{
    padding-left: 20px  !important;
 }

.uppy-Dashboard-Item-name {
	font-size: 18px  !important;
	margin-bottom: 15px  !important;
 }

.uppy-Dashboard-Item-status {
	font-size: 14px  !important;
 }

[dir="ltr"] .uppy-size--md .uppy-Dashboard-Item-action--remove{
	display: none  !important;
 } 
.uppy-size--md .uppy-Dashboard-Item {
	margin: 20px  !important;
	width: auto  !important;
 }
.uppy-size--md .uppy-Dashboard-Item-preview {
	display: none  !important;
 }
.uppy-size--lg .uppy-Dashboard-Item-preview {
	border: 1px solid transparent  !important;
 }
.uppy-size--md .uppy-Dashboard-inner {
	border: 1px dashed transparent  !important;
 }
.uppy-size--md .uppy-DashboardContent-bar {
	display: none  !important;
 }
[data-uppy-theme=dark] .uppy-DashboardContent-back {
	display: none  !important;
 }
 [dir="ltr"] .uppy-size--md .uppy-DashboardContent-addMore{
	display: none  !important;
 }
.uppy-Dashboard-files {
	overflow-y: hidden  !important;
}
.uppy-size--md .uppy-Dashboard-AddFiles-title button {
	margin-top: 20px;
	background-color: #8687FF;
    line-height: 1;
    padding: 10px 16px;
    border-radius: 5px;
	color: white;
	margin-top: 20px;
	font-size: 18px;
	font-weight: 700;
}
.uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
	border-bottom: 0px solid transparent !important;
  }
[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-title {
    width: 300px;
}
